import React from 'react';
import {Text} from 'wix-ui-tpa/Text';
import {classes, st} from './ShippingSectionOverride.st.css';

export interface ShippingSectionTextProps {
  dataHook: string;
  text: string;
  isTitle?: boolean;
  lineBreak?: boolean;
}

export const ShippingSectionText: React.FC<ShippingSectionTextProps> = ({
  dataHook,
  text,
  isTitle = false,
  lineBreak = false,
}) => {
  const textClassName = isTitle ? classes.title : classes.text;

  return (
    <div className={isTitle ? classes.wrapper : ''}>
      <Text className={lineBreak ? st(textClassName, classes.lineBreak) : textClassName} data-hook={dataHook}>
        {text}
      </Text>
    </div>
  );
};
